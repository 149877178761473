@use '@compliance.ai/styles/dist/variables';

.concept-details {
  padding: 2.5rem 3.75rem;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: variables.$spacing-m;
    }
  }

  &__body {
    margin-top: 1.25rem;
    display: flex;
    flex-flow: row wrap;
    column-gap: 2%;
    row-gap: variables.$spacing-m;

    .input_1 {
      flex: 1 0 11%;
    }

    .input_2 {
      flex: 1.8 0 23%;
    }

    .input_4 {
      flex: 3.8 0 48%;
    }

    .input_8 {
      flex: 8 0 100%;
    }
  }
}
