@use '@compliance.ai/styles/dist/variables';

.concept-page {
  &__tabs-bar {
    justify-content: space-between;
    padding: 0 60px;

    & > div > div {
      display: flex;
    }

    div:nth-child(2) {
      padding-right: 0;
      border-left: none;
    }
  }
}
