@use '@compliance.ai/styles/dist/variables';

.concept-status {
  padding: 15px 0 15px 15px;
  display: flex;
  justify-content: flex-end;
  gap: variables.$spacing-l;

  &__dates {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding-right: variables.$spacing-l;
    border-right: variables.$border-base;
    font-style: italic;
  }

  &__status {
    min-width: 150px;

    & > div {
      padding-left: variables.$spacing-xs;

      & .concept-status__status__item {
        color: variables.$primary-color-2;
      }
    }

    p {
      display: flex;
      align-items: center;
      gap: variables.$spacing-semi-xs;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: variables.$spacing-semi-xs;
    }

    &__active {
      & > div {
        background-color: variables.$secondary-color-3;
      }
    }

    &__deactivated {
      & > div {
        background-color: variables.$secondary-color-5;
      }
    }
  }
}

div[role='tooltip'] > div > div {
  & p {
    color: variables.$primary-color-2;
  }

  &:has(.active) {
    & > div > div:hover {
      background-color: variables.$secondary-color-14;
      opacity: 0.8;
    }
    background-color: variables.$secondary-color-3;
  }

  &:has(.deactivated) {
    & > div > div:hover {
      background-color: variables.$secondary-color-13;
      opacity: 0.8;
    }
    background-color: variables.$secondary-color-5;
  }
}
